import React, { Component } from 'react'
import {Rnd} from 'react-rnd'
import WindowBar from './windowbar'
import errorSign from '../images/ui/error_modal.png'

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
    this.onDrag = this.onDrag.bind(this);
  }

  state = {
    zIndex: 1
  }

  onDrag() {
    global.counter += 1;
    this.setState({
      zIndex: global.counter
    });
  }

  hide() {
    this.setState({
      visibility: 'hidden'
  })
  }

  componentDidMount(){
    this.rnd.updatePosition({ x: Math.random() * (window.innerWidth - 200), y: Math.random() * (window.innerHeight - 200) })
  }

  render() {
    return (
        <Rnd
        ref={c => { this.rnd = c; }}
        className='window'
        style={this.state}
        enableResizing={false}
        onDragStart={this.onDrag}
        default={{x: 200, y: 200, width: 500}}>
          <WindowBar 
          buttons={false} />
          <div className='error-modal-wrapper'>
            <div className='error-modal-innerwrapper'>
              <img src={errorSign} alt='' />
              <div className='error-modal-content'>
                <h1>{this.props.title}</h1>
                <p>{this.props.body}</p>
              </div>
            </div>
            <div className='error-ok' onClick={this.hide}>
              OK
            </div>
          </div>
        </Rnd>
    );
  }
}

export default ErrorModal;
