import React from 'react'

import PreviewImage from './previewImage'
import ImageBox from './image'
import TextEdit from './textEdit'
import ErrorModal from './errorModal'
import WebView from './webview'

const Desktop = ({ page }) => (
  <div 
  className={ 'desktop' }
  style={ { backgroundImage: `url(${page.bg_image})` } }>
    <h1>{page.title}</h1>
    { page.desktopImages && page.desktopImages.map(((image,index) => 
      <ImageBox 
        key={index}
        image={image.image}
        title={image.title}
        size={image.displaySize}
      />
      ))}
    { page.webView && page.webView.map(((content, index) =>
      <WebView
        key={index}
        url={content.url}
        screenshot={content.screenshot}
      />
      ))}
    { page.images && page.images.map(((image,index) => 
      <PreviewImage 
        key={index}
        image={image.image}
        title={image.title}
        size={image.displaySize}
      />
      ))}
    { page.textEdit && page.textEdit.map(((text,index) => 
      <TextEdit 
        key={index}
        title={text.title}
        text={text.html}
      />
      ))}
    { page.errorModal && page.errorModal.map(((content, index) =>
      <ErrorModal
        key={index}
        title={content.title}
        body={content.body}
      />
    ))}
  </div>
)

export default Desktop