import React, { Component } from 'react'
import {Rnd} from 'react-rnd'
import WindowBar from './windowbar'

class WebView extends Component {
    constructor(props) {
        super(props);
        this.hide = this.hide.bind(this);
        this.onDrag = this.onDrag.bind(this);
    }

    state = {
        zIndex: 1
    }

    componentDidMount(){
    // this.rnd.updatePosition({ x: Math.random() * (window.innerWidth - 200), y: Math.random() * (window.innerHeight - 200) })
    }
    
    onDrag() {
        global.counter += 1;
        this.setState({
          zIndex: global.counter
        });
      }
    
    hide() {
        this.setState({
            visibility: 'hidden'
        });
    }

    render() {
        return (
            <Rnd
            ref={c => { this.rnd = c; }}
            className='window'
            style={this.state}
            onDragStart={this.onDrag}
            enableResizing={false}
            default={{x: 200, y: 200}}>
                <WindowBar 
                buttons={true}
                hidefn={this.hide}
                url={this.props.url} />
                <div>
                    <img src={this.props.screenshot} alt=''></img>
                </div>
            </Rnd>
        );
    }
}

export default WebView;
