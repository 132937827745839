import React, { Component } from 'react'

class ImageBox extends Component {
  constructor(props){
    super(props);
    this.width = 0;

    if(this.props.size == 'large'){
      this.width = `${Math.floor(800 + 200 * Math.random())}px`;
    } else if(this.props.size == 'medium') {
      this.width = `${Math.floor(500 + 200 * Math.random())}px`;
    } else {
      this.width = `${Math.floor(300 + 100 * Math.random())}px`;
    }
  }

  state = {
    loaded: false,
    width: ''
  }

  style = {
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 1,
    top: `${Math.random() * (global.innerHeight - 200) }px`,
    left: `${Math.random() * (global.innerWidth - 300)}px`
  }

  componentDidMount(){
    this.setState({
      loaded: true,
      width: this.width
    })
  }

  render() {
    return (
      <img src={this.props.image}
       alt=""
       style={ this.state.loaded ? this.style : {} }
       width={ this.state.width }
       className='imagebox'
       />
    );
  }
}

export default ImageBox;
