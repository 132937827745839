import React, { Component } from 'react'
import {Rnd} from 'react-rnd'
import WindowBar from './windowbar.js'
import scale from '../images/ui/text_edit_rule.png'

class TextEdit extends Component {
  constructor(props) {
    super(props);
    this.onDrag = this.onDrag.bind(this);
    this.hide = this.hide.bind(this);

    this.onScroll = this.onScroll.bind(this);
  }

  state = {
    zIndex: 1
  }

  onDrag() {
    global.counter += 1;
    this.setState({
      zIndex: global.counter
    });
  }

  hide() {
    this.setState({
      visibility: 'hidden'
    });
  }

  onScroll(e) {
    // e.preventDefault();
    // debugger;
  }

  componentDidMount(){
    this.rnd.updatePosition({ x: Math.random() * (window.innerWidth - 100), y: Math.random() * (window.innerHeight - 200) })
  }

  render() {
    return (
        <Rnd className='textedit window'
        ref={c => { this.rnd = c; }}
        default={{ x: 500, y: 500, width: 480, height: 600 }}
        minWidth={425}
        maxWidth={800}
        onDragStart={this.onDrag}
        style={this.state}>
          <WindowBar title={this.props.title}
          buttons={true}
          hidefn={this.hide} />
          <div className='textedit-scale'>
            <img src={scale} alt=''></img>
          </div>
          <div className='textedit-content'
          dangerouslySetInnerHTML={ {__html: this.props.text}}
          onScroll={this.onScroll}>
          </div>
        </Rnd>
    );
  }
}

export default TextEdit;
