import React from 'react'
import Desktop from '../components/desktop'
// import replaceVerticalScrollByHorizontal from '../components/sidescroll'
import Layout from '../components/layout'

global.counter = 1;

// window.addEventListener('wheel', replaceVerticalScrollByHorizontal);

const IndexPage = ({ data }) => (
  <Layout>
    <div
    className='desktop-wrapper'
    onScroll={(e) => {
      // console.log("here");
      // if (e.deltaY !== 0) {
      //   window.scroll(window.scrollX + e.deltaY, window.scrollY);
      //   e.preventDefault();
      // }
    }}>
      {data.allMarkdownRemark.edges.map(page => 
        <Desktop 
        page={page.node.frontmatter}
        key={page.node.id}
        />
        )}
    </div>
  </Layout>
)

export default IndexPage


export const indexPage = graphql`
  query indexPage {
    allMarkdownRemark(sort:{fields:[frontmatter___path]}) {
      edges {
        node {
          id
          frontmatter {
            textEdit {
              body
              title
              html
            }
            title
            path
            bg_image
            images {
              image
              title
              displaySize
            }
						errorModal {
						  body
						  title
            }
            webView {
              screenshot
              url
            }
            desktopImages {
              displaySize
              image
              title
            }
          }
        }
      }
    }
  }
`
