import React, { Component } from 'react'
import {Rnd} from 'react-rnd'
import WindowBar from './windowbar'

class PreviewImage extends Component {
  constructor(props) {
    super(props);
    this.onDrag = this.onDrag.bind(this);
    this.hide = this.hide.bind(this);

    if(this.props.size == 'large'){
      this.width = `${Math.floor(800 + 200 * Math.random())}px`;
    } else if(this.props.size == 'medium') {
      this.width = `${Math.floor(500 + 200 * Math.random())}px`;
    } else {
      this.width = `${Math.floor(300 + 100 * Math.random())}px`;
    }
  }

  state = {
    zIndex: 1
  }

  onDrag() {
    global.counter += 1;
    this.setState({
      zIndex: global.counter
    });
  }

  hide() {
    this.setState({
      visibility: 'hidden'
    })
  }

  componentDidMount(){
    this.rnd.updatePosition({ x: Math.random() * (window.innerWidth - 200), y: Math.random() * (window.innerHeight - 200) })
  }

  render() {
    return (
        <Rnd
        ref={c => { this.rnd = c; }}
        className='imagewindow window'
        default={{width: this.width, height: 'auto',}}
        minWidth={300}
        maxWidth={1000}
        minHeight={200}
        maxHeight={800}
        onDragStart={this.onDrag}
        style={this.state}>
          <WindowBar title={this.props.title}
          buttons={true}
          hidefn={this.hide} />
          <div className='imagewindow-wrapper'>
            <img src={this.props.image} alt=""/>
          </div>
        </Rnd>
    );
  }
}

export default PreviewImage;
